import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Section, SectionHeader, SectionContent, SectionColumn } from "@layouts/Section"
import * as Spacer from "@atoms/Spacer"
import Seo from "@components/atoms/Seo"
import { NewsList } from "@projects/NewsList"
import ListController from "@projects/ListController"
import Border from "@atoms/Border"

//ログイン後のindexページ
//ログインしてない場合は全部indexにリダイレクト
function NewsListPage({data, pageContext}) {
    console.log(data, pageContext)
    const { news, categorys, ogpImage} = data

    let categorysOptions = categorys.group.map(({ fieldValue }, i) => {
        return {
            value: fieldValue,
            label: fieldValue,
        }
    })
    categorysOptions.unshift({
        value: 'all',
        label: 'すべて',
    })
    let yearsOptions = pageContext.years.map((val)=>{
        return {
            value: val,
            label: val,
        }
    })
    yearsOptions.unshift({
        value: 'all',
        label: 'すべて',
    })
    return (
        <>
            <Seo
                subtitle="News"
                image={ogpImage.publicURL}
            />
            <Spacer.Header />
            <Section>
                <SectionHeader
                    title="News"
                    text="ニュース"
                />
                <SectionContent>
                    <SectionColumn>
                        <ListController label="期間"
                            parent="news"
                            current={pageContext.year}
                            options={yearsOptions}
                            sortType="year"
                        />
                        <ListController label="カテゴリ"
                            parent="news"
                            current={pageContext.category}
                            options={categorysOptions}
                            sortType="category" />
                    </SectionColumn>
                    <Border />
                    <NewsList data={news.edges} />
                </SectionContent>
                {/* <SectionMoreLink
                    title="More"
                    text="もっとみる"
                    to="/news/"
                /> */}
            </Section>
        </>
    )
}

export const pageQuery = graphql`
	query NewsList(
        $categoryReg: String!,
        $periodStartDate: Date!,
        $periodEndDate: Date!
    ){
		news: allWpNews(
			filter: {
                acf_news :{ category : { regex: $categoryReg } }
                date: { gte: $periodStartDate, lt: $periodEndDate }
            }
			limit: 9999
			sort: { fields: [date], order: DESC }) {
			edges {
				node {
					...News
				}
			}
		}
        categorys : allWpNews {
            group(field: acf_news___category) {
                fieldValue
                totalCount
            }
        }
        ogpImage : file(relativePath: { eq: "OGP_news.png" }) {
            publicURL
        }
	}
`
export default NewsListPage